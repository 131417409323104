<template>
  <div class="login">
    <el-row :gutter="20">
      <el-col :span="16">
        <el-card class="box-card" style="width:480px">
          <h2>后台系统登录</h2>
          <el-form
            class="login-form"
            :model="loginForm"
            :rules="loginRules"
            ref="form"
            @submit.native.prevent="login"
          >
            <el-form-item prop="username">
              <span class="svg-container">
                <font-awesome-icon :icon="['fa', 'user']" size="1x" />
              </span>
              <el-input v-model="loginForm.name" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <span class="svg-container">
                <font-awesome-icon :icon="['fa', 'lock']" size="1x" />
              </span>
              <el-input
                v-model="loginForm.password"
                placeholder="密码"
                :type="passwordType"
                @keyup.enter.native="handleLogin"
                tabindex="2"
                autocomplete="on"
              ></el-input>
              <span class="show-pwd" @click="showPwd">
                <font-awesome-icon :icon="passwordType === 'password' ? 'eye-slash' : 'eye'" />
              </span>
            </el-form-item>
            <el-button
              :loading="loading"
              type="primary"
              style="width:100%;margin-bottom:30px;"
              @click.native.prevent="handleLogin"
            >登录</el-button>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "signin",
  data() {
    const validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else { 
        setTimeout(() => {
            if (this.loginForm.name !== '') {
            this.$refs.form.validateField('password');
            }
            callback();
        }, 1000);
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        setTimeout(() => {
            if (this.loginForm.password !== '') {
            this.$refs.form.validateField('password');
            }
            callback();
        }, 1000);
      }
    };
    return {
      loading: false,
      passwordType: "password",
      loginForm: {
        name: "admin",
        password: "admin"
      },
      loginRules: {
        name: [{ required: true, trigger: "blur", validator: validateName }],
        password: [
          { required: true, trigger: "blur", validator: validatePassword }
        ]
      }
    };
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    showPwd() {
      this.passwordType = this.passwordType === "text" ? "password" : "text";
    },
     handleLogin(){
       this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          this.login(this.loginForm).then(() => {
            this.loading = false;
            this.$router.replace({name: 'Dashboard'});
          }).catch((e)=>{
            console.log(e);
            this.loading = false;
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
};
</script>
<style lang="stylus">.el-input__prefix .el-input__icon {
  font-size: 20px;
}

.svg-container {
  display: flex;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  position: relative;

  svg {
    position: absolute;
    top: 10px;
    z-index: 1;
    left: 10px;
    font-size: 19px;
  }
}

.show-pwd {
  position: absolute;
  right: 10px;
  top: 2px;
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
</style>
<style scoped>
.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d1f20;
  position: relative;
  height: 100%;
  margin: auto;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
}

.login-button {
  width: 100%;
  margin-top: 40px;
}
.login-form {
  width: 290px;
}
.forgot-password {
  margin-top: 10px;
}
</style>
<style lang="stylus">
.login .el-input__prefix {
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;

  .el-input__icon {
    width: 30px;
  }
}

.login .el-input input {
  padding-left: 35px;
}

.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;
}

h2 {
  font-family: 'Open Sans';
  letter-spacing: 1px;
  font-family: Roboto, sans-serif;
  padding-bottom: 20px;
}

a {
  color: #fff;
  text-decoration: none;

  &:hover, &:active, &:focus {
    color: lighten(#fff, 7);
  }
}

.login .el-card {
  width: 340px;
  display: flex;
  justify-content: center;
}
</style>
